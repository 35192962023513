// Should align to set of Stripe features lookup keys
const STRIPE_FEATURES_LOOKUP_KEYS = [
  'starter-features',
  'basic-features',
  'pro-features',
  'empire-features',
] as const;

export type StripeFeature = (typeof STRIPE_FEATURES_LOOKUP_KEYS)[number];

// Map of Stripe plan IDs to their display name
export const STRIPE_PRODUCTS: { [key: string]: string } = {
  // Test Mode Products
  prod_QgCd9NdfFj9Y8q: 'Starter Tier (TEST)',
  prod_QgCfVE7XUIx3ij: 'Basic Tier (TEST)',
  prod_QgDYfdDG5gSDvh: 'Pro Tier (TEST)',
  prod_QgDaav3rN6SIXM: 'Empire Tier (TEST)',
  // Live Production Products
  prod_QovNxmfzXWKbZn: 'Basic Tier',
};
