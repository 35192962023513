import { StripeFeature } from './stripe';

// Set of available app features
export enum RcFeature {
  AUTOMATIC_REMINDERS = 'Automatic Reminders',
  CALENDAR_URLS = 'Calendar URLs',
  CONVERSATION_HISTORY = 'Conversation History',
  CUSTOM_TASKS = 'Custom Tasks',
  DOCUMENT_SIGNING = 'Document Signing',
  EMAIL_NOTIFICATIONS = 'Email Notifications',
  LISTING_INTEGRATIONS = 'Listing Integrations',
  MOVEIN_MOVEOUT_REPORTS = 'Move-in / Move-out Reports',
  PRIVATE_EMAIL_ADDRESS = 'Private Email Address',
  PRIVATE_PHONE_NUMBER = 'Private Phone Number',
  TASKS_LIBRARY = 'Tasks Library',
  TASK_HISTORY = 'Task History',
  TASK_REMINDERS = 'Task Reminders',
  TASK_SCHEDULING = 'Task Scheduling',
  TENANT_PORTAL_RESOURCES = 'Tenant Portal Resources',
  TENANT_TASKS_PAGE = 'Tenant Tasks Page',
  TEXT_MESSAGE_NOTIFICATIONS = 'Text Message Notifications',
  WEATHER_SCHEDULING = 'Weather Scheduling',
}

// Mapping of features to their brief text descriptions
export const FEATURE_DESCRIPTIONS: { [key in RcFeature]: string } = {
  [RcFeature.AUTOMATIC_REMINDERS]:
    'Send task assignees automatic reminders for all of their incomplete tasks',
  [RcFeature.CALENDAR_URLS]:
    'Create shared calendars showing events with things like upcoming tasks and due dates that can be subscribed to by you and your tenants',
  [RcFeature.CONVERSATION_HISTORY]:
    'View the entire history of all messages sent to and received from tenants',
  [RcFeature.CUSTOM_TASKS]:
    'Create custom tasks from scratch, or modify tasks from the task library',
  [RcFeature.DOCUMENT_SIGNING]:
    'Upload documents and handle digital signing for things like leases, amendments, agreements, etc.',
  [RcFeature.EMAIL_NOTIFICATIONS]:
    'Send tenants email notifications when tasks are assigned to them',
  [RcFeature.LISTING_INTEGRATIONS]:
    'Integrate with property listing sites like Zillow and Apartments.com to simplify managing vacancy listings and finding new tenants',
  [RcFeature.MOVEIN_MOVEOUT_REPORTS]:
    'Create and share reports on the state of a property at tenant move-in and move-out',
  [RcFeature.PRIVATE_EMAIL_ADDRESS]:
    'Receive a private unique email address to avoid sharing personal contact information with tenants and set an available contact window',
  [RcFeature.PRIVATE_PHONE_NUMBER]:
    'Receive a private unique phone number to avoid sharing personal contact information with tenants and an set available contact window',
  [RcFeature.TASKS_LIBRARY]:
    'Create and assign prebuilt tasks from the set of available templates in the task library',
  [RcFeature.TASK_HISTORY]:
    'View the entire history of updates to tasks from the time they are first created',
  [RcFeature.TASK_REMINDERS]:
    'Send regular automatic reminders about outstanding tasks to task assignees',
  [RcFeature.TASK_SCHEDULING]:
    'Setup schedules to create new tasks on a configurable recurring basis',
  [RcFeature.TENANT_PORTAL_RESOURCES]:
    'Create a dedicated resources site for tenants to access commonly requested information like move in details, important contacts, etc.',
  [RcFeature.TENANT_TASKS_PAGE]:
    'Provide a dedicated page for your tenants to view and complete their tasks',
  [RcFeature.TEXT_MESSAGE_NOTIFICATIONS]:
    'Send text message notifications for newly created tasks to assignees',
  [RcFeature.WEATHER_SCHEDULING]:
    'Create tasks automatically based on the weather at a property, such as rain or snowfall amounts, temperatures reached, and more',
};

// Mapping of Stripe features to internal app feature entitlements
export const FEATURE_ENTITLEMENTS: {
  [key in StripeFeature]: RcFeature[];
} = {
  // Free
  'starter-features': [
    //RcFeature.MOVEIN_MOVEOUT_TASKS,
    RcFeature.TASKS_LIBRARY,
    RcFeature.TENANT_TASKS_PAGE,
    RcFeature.EMAIL_NOTIFICATIONS,
  ],
  // Paid
  'basic-features': [
    RcFeature.CUSTOM_TASKS,
    //RcFeature.TASK_HISTORY,
    RcFeature.TASK_SCHEDULING,
    RcFeature.TEXT_MESSAGE_NOTIFICATIONS,
    RcFeature.TENANT_PORTAL_RESOURCES,
  ],
  // Future
  'pro-features': [
    RcFeature.CALENDAR_URLS,
    RcFeature.CONVERSATION_HISTORY,
    RcFeature.DOCUMENT_SIGNING,
    RcFeature.LISTING_INTEGRATIONS,
    RcFeature.PRIVATE_EMAIL_ADDRESS,
    RcFeature.PRIVATE_PHONE_NUMBER,
    RcFeature.WEATHER_SCHEDULING,
  ],
  'empire-features': [],
};

function onlyUnique<T>(value: T, index: number, array: Array<T>) {
  return array.indexOf(value) === index;
}

/**
 * Returns list of all RcFeatures entitled by the provided Stripe feature entitlements
 *
 * Currently explicitly adds 'starter-features' to give all users the "free tier" features
 */
export function getAppFeaturesFromStripeEntitlements(
  ...stripeEntitlements: (StripeFeature | string)[]
): RcFeature[] {
  return ['starter-features', ...stripeEntitlements]
    .flatMap(
      (stripeFeature) => FEATURE_ENTITLEMENTS[stripeFeature as StripeFeature],
    )
    .filter(onlyUnique);
}
